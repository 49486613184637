<template>
    <p>Hello!</p>
</template>

<script>
export default {
    name: "Login"
}
</script>

<style scoped>

</style>
