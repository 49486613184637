import {
    deleteRequest,
    getRequest,
    patchRequest,
    postRequest
} from "./request";

// a getRequest can NOT have a body, use a postRequest instead
const getX = async (xId) => getRequest(`/x/${xId}`);
const postX = async (body) => postRequest('/x', body);
const patchX = async (xId, body) => patchRequest(`/x/${xId}`, body);
const deleteX = async (xId) => deleteRequest(`/x/${xId}`);

const getHelloFromBackend = async () => getRequest(`/`);

// QRCode Routes
const getQrCodes = async () => getRequest('/codes');
const postQrCodes = async (payload) => postRequest('/codes', payload);
const verifyQRCodeKey = async (compressedKey) => getRequest(`/code/verify/${compressedKey}`);
const registerQRCode = async (compressedKey, body) => patchRequest(`/code/${compressedKey}`, body);

// Event routes
const postEvent = async (body) => postRequest('/event', body);
const getEvents = async () => getRequest('/event');
const getCurrentEvent = async (key) => getRequest(`/event/current/${key}`);
const registerForEvent = async (eventId, compressedKey) => patchRequest(`/event/${eventId}`, compressedKey);
const requestBlockUsers = async (eventId) => postRequest(`/event/block/${eventId}`);
const setCodeAttending = async (body) => postRequest('/event/attend', body);

const validateAdminPassword = async (token) => getRequest(`/token/admin/${token}`);
const validateAdminToken = async (token) => getRequest(`/token/admin/validate/${token}`);

export {
    getHelloFromBackend,

    getX,
    postX,
    patchX,
    deleteX,

    getQrCodes,
    postQrCodes,
    verifyQRCodeKey,
    registerQRCode,

    postEvent,
    getEvents,
    getCurrentEvent,
    registerForEvent,
    requestBlockUsers,
    setCodeAttending,

    validateAdminPassword,
    validateAdminToken,
};

