import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import 'typeface-roboto/index.css';

Vue.use(Vuetify, {
    iconfont: 'mdi'
});

export default new Vuetify({
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                // HOW TO USE: if the Vue component supports color prop use it like color="gruen"
                // otherwise create a class and use like background-color: var(--v-gruen-base);
                weiss: '#f8f8f8',           //Standard Hintergrundfarbe
                black: '#000000',
                backgroundYellow: '#fded4f',
            },
        },
    },
});
