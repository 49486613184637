<template>
    <v-app>
        <!--<div v-if="backendResponse">
                    Response from backend: {{ backendResponse }}
                </div>
                <div v-else-if="backendResponseFailed">
                    Requesting backend failed
                </div>
                <div v-else>
                    Requesting backend...
                </div>-->
        <router-view />
        <Snackbar />
    </v-app>
</template>

<script>
import {mapActions, mapGetters, mapState, mapMutations} from "vuex";
import * as backend from "./api/backend";
import Snackbar from "@/components/Utils/Snackbar";

export default {
    name: "App",
    components: {Snackbar},
    data: () => ({
        backendResponse: "",
        backendResponseFailed: false,
    }),
    computed: {},
    watch: {},
    async mounted() {
        console.log('Requesting backend');
        // try {
        //     const helloFromBackendResponse = await backend.getHelloFromBackend();
        //     this.backendResponse = await helloFromBackendResponse.text();
        //     console.log(this.backendResponse);
        // } catch (err) {
        //     console.error(err);
        //     this.backendResponseFailed = true;
        //     console.log('Requesting backend failed');
        // }
    },
    beforeDestroy() {
    },
    methods: {
    },
};
</script>

<style lang="scss">
// universal settings for font
.v-application {
    font-size: 20px;
}
</style>
