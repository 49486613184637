<template>
    <div
        id="background"
        class="blackBackgroundWhiteText d-flex flex-column justify-center align-center"
    >
        <div
            id="container"
            class="d-flex flex-column justify-center align-center"
        >
            <h1 class="text-center">Clubbing</h1>

            <video class="my-4" width="400" controls>
                <source :src="trailer" type="video/mp4">
                Ihre Browser unterstützt keine HTML-Videos.
            </video>

            <p>Scannen Sie hier Ihren QR-Code ein</p>
            <v-btn
                class="yellowBtn mt-2"
                dark
                @click="() => { qrScanActive = true; }"
            >
                Scannen
            </v-btn>
        </div>

        <QRScanDialog
            v-if="qrScanActive"
            :close-function="() => { qrScanActive = false; }"
            :user-group="'user'"
            @QRCodeScanned="loginIGuess"
        />
    </div>
</template>

<script>
import {mapActions, mapMutations} from "vuex";
import QRScanDialog from "../components/QRScanDialog";
import { decompress } from "shrink-string";
import trailer from '@/assets/trailer.mp4';

export default {
    name: "Home",
    components: { QRScanDialog },
    data: () => ({
        qrScanActive: false,

        trailer,
    }),
    async mounted() {
        // Check if URL contains params of QRCode
        if (this.$route.query && this.$route.query.key) {
            await this.loginIGuess(this.$route.query.key);
        }

        // const compressedID = await compress('61977d11b9468b5630abaecc');
        // console.log('compressedID', compressedID);
    },
    methods: {
        ...mapMutations('snackbar', [ 'showSnackbar' ]),
        ...mapActions('auth', [ 'loginUserWithKey' ]),
        async loginIGuess(givenKey) {
            const isKeyFormatOk = await this.checkKeyFormat(givenKey);
            if (isKeyFormatOk) {
                const userLoggedIn = this.loginUserWithKey(givenKey);
                if (userLoggedIn) {
                    this.nextPage();
                    this.qrScanActive = false;
                    return;
                }
            }

            this.showSnackbar({ color: 'error', message: 'Konnte nicht verifiziert werden!' });
        },
        async checkKeyFormat(givenKey) {
            try {
                const decompressedKey = await decompress(givenKey);
                // Check if decompressedKey is of format uuid
                // TODO: Improve UUID Check, seems too easy
                // Regex from stackoverflow: https://stackoverflow.com/a/20988824
                return !!decompressedKey.match(/^(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)/i);
            } catch (e) {
                console.warn(e);
                return false;
            }
        },
        nextPage() {
            this.$router.push('register');
        },
    }
}
</script>

<style lang="scss" scoped>
#background {
    height: 100vh;
    width: 100vw;
}

#container {
    max-width: 60vw;
}

.yellowBtn {
    background-color: var(--v-backgroundYellow-base) !important;
    color: black !important;
    border-radius: 0;
}

@media (max-width: 800px) {
    #container {
        max-width: 90vw;
    }
}
</style>
