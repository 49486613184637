import store from '@/store';
import loading from '@/store/modules/util';
import { ConnectionRefusedError } from '@/util/error';
import { getBackendAddress } from '../util/getBackendAddress';

const addAuthorization = async (fetchConfig = {}) => ({
  ...fetchConfig,
  headers: {
    ...(store.state.auth.token ? { Authorization: `Bearer ${store.state.auth.token}` } : {}),
    ...(fetchConfig.headers ? fetchConfig.headers : {}),
  },
});

const request = async (path, config = {}) => {
  try {
    
    store.commit('util/setLoadingShown', true);

    const url = path.startsWith(getBackendAddress())
    ? path
    : `${getBackendAddress()}${path}`;

    const response = await fetch(url, await addAuthorization(config));
    
    if ((response.ok || response.status === 304)
      && !store.state.connection.backendReachable) {
      store.commit('connection/setBackendReachable', true);
    }

    store.commit('util/setLoadingShown', false);
    return response;
  } catch (error) {
    if (store.state.connection.backendReachable) {
      store.commit('connection/setBackendReachable', false);
    }
    console.error(error)
    throw new ConnectionRefusedError('Backend nicht erreichbar.');
  }
};

const getETag = (path) => {
  const etags = JSON.parse(localStorage.getItem('etags')) || {};
  return etags[path];
};
const setETag = (path, etag) => {
  const etags = JSON.parse(localStorage.getItem('etags')) || {};
  const newEtags = {
    ...etags,
    [path]: etag,
  };
  localStorage.setItem('etags', JSON.stringify(newEtags));
};

// Get request are also cached in localStorage with ETags
const getRequest = async (path) => {
  // // Get cached data
  // const etag = getETag(path);
  // const cacheName = 'backend';
  // const cache = await caches.open(cacheName);
  //
  // const cachedResponse = await cache.match(path);
  //
  // const config = {
  //   ...( // Add ETag if cached
  //     etag && cachedResponse
  //       ? { headers: { 'If-None-Match': etag } }
  //       : undefined
  //   ),
  //   method: 'GET',
  // };

  // try {
    const response = await request(path);

    // // Etag set, response can be used from cache
    // if (response.status === 304 && cachedResponse) {
    //   return cachedResponse;
    // }

    // Cache and save ETag for less network traffic
    if (response.ok && response.headers.has('ETag')) {
      setETag(path, response.headers.get('ETag'));
      // cache.put(path, response.clone());
      return response;
    }

    // Cache only for offline mode
    // if (response.ok) {
    //   cache.put(path, response.clone());
    // }

    return response;
  // } catch (error) {
  //   // Try to use cached version
  //   // if (cachedResponse) {
  //   //   // eslint-disable-next-line no-console
  //   //   console.warn(`Backend unreachable. Using cached version for: ${cachedResponse.url}`);
  //   //
  //   //   return cachedResponse;
  //   // }
  //   throw error;
  // }
};

const putRequest = async (path, body) => {
  const config = {
    method: 'PUT',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return request(path, config);
};

const patchRequest = async (path, body) => {
  const config = {
    method: 'PATCH',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return request(path, config);
};

const postRequest = async (path, body) => {
  const config = {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return request(path, config);
};

const deleteRequest = async (path, body) => {
  const config = {
    method: 'DELETE',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return request(path, config);
};

const filePostRequest = async (path, file) => {
  const formData = new FormData();
  formData.append('file', file);
  const config = {
    method: 'POST',
    body: formData,
  };
  return request(path, config);
};

const createFileXmlPatchRequest = async (path, file) => {
  const xmlHttpRequest = new XMLHttpRequest();

  const url = path.startsWith(getBackendAddress())
      ? path
      : `${getBackendAddress()}${path}`;

  xmlHttpRequest.open('PATCH', url, true)
  xmlHttpRequest.setRequestHeader('Authorization', `Bearer ${store.state.auth.token}`);

  return xmlHttpRequest;
}

const createFileXMLHttpRequest = (path, file) => {
  const xmlHttpRequest = new XMLHttpRequest();
  const url = path.startsWith(getBackendAddress())
    ? path
    : `${getBackendAddress()}${path}`;

  xmlHttpRequest.open('POST', url, true)
  xmlHttpRequest.setRequestHeader('Authorization', `Bearer ${store.state.auth.token}`);
  // the editor does not know the new FileId of a Created Document, but needs
  // it to generateScreenshots (which are appended to fileId)
  xmlHttpRequest.onreadystatechange = function() {
    if (xmlHttpRequest.readyState == 4) {
        store.commit('uploadMessage/setNewFileId', JSON.parse(xmlHttpRequest.response)._id);
    }
  }
  return xmlHttpRequest;
};

export {
  request,
  getRequest,
  putRequest,
  patchRequest,
  postRequest,
  createFileXMLHttpRequest,
  createFileXmlPatchRequest,
  deleteRequest,
  filePostRequest,
}
