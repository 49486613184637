<template>
    <div>
        <!--#region Current event -->
        <h2>Aktuelles Event</h2>

        <div class="d-flex justify-space-between" v-if="currentEvent">
            <div style="width: 100%">
                <div class="mb-1 d-flex justify-space-between">
                    <p><b>Name:</b></p>
                    <p>{{ currentEvent.name }}</p>
                </div>
                <div class="mb-1 d-flex justify-space-between">
                    <p><b>Datum:</b></p>
                    <p>{{ currentEvent.date }}</p>
                </div>
                <div class="mb-1 d-flex justify-space-between">
                    <p><b>Ort:</b></p>
                    <p class="multilineText text-right">{{ currentEvent.location }}</p>
                </div>
                <div class="mb-1 d-flex justify-space-between">
                    <p><b>Beschreibung:</b></p>
                    <p class="multilineText text-right">{{ currentEvent.description }}</p>
                </div>
            </div>
        </div>
        <div class="mb-4" v-else>
            <p>Es gibt es kein aktuelles Event. Legen Sie über den Knopf 'Neues Event anlegen' eins an!</p>
        </div>

        <div class="btnContainer d-flex justify-space-around mb-8">
            <v-btn
                class="yellowBtn"
                @click="scanMode = true"
                dark
            >
                QR-Codes scannen
            </v-btn>

            <v-btn
                class="yellowBtn"
                @click="createEventDialog = true"
                dark
            >
                Neues Event anlegen
            </v-btn>

            <v-btn
                @click="confirmBlockUsersDialog = true"
                class="yellowBtn"
                dark
            >
                Nutzer sperren
            </v-btn>
        </div>
        <!--#endregion -->

        <!-- Participants list -->
        <div v-if="showParticipants">
            <div class="d-flex justify-space-between align-center">
                <h2>Teilnehmer von <i>{{ participantsEvent.name }}</i> - {{ participantsEvent.computedParticipants }}</h2>
                <v-btn
                    class="yellowBtn"
                    @click="resetParticipants"
                    dark
                >
                    Zurück
                </v-btn>
            </div>

            <v-data-table
                :headers="participantsHeaders"
                :items="participantsItems"
                :header-props="headerProps"
                :footer-props="footerProps"
                :single-expand="true"
                :expanded.sync="expanded"
                item-key="owner.name"
                no-data-text="Keine Teilnehmer verfügbar"
                show-expand
                dense
                dark
            >
                <template v-slot:expanded-item="{ headers, item }">
                    <td v-if="item.owner" :colspan="headers.length">
                        <p v-if="item.entourage[0].name !== ''">+1: {{ item.entourage[0].name + ', ' + item.entourage[0].gender + ', ' + item.entourage[0].location }}</p>
                        <p v-else>Keine weiteren Leute zu diesem Code registriert</p>
                        <p v-if="item.entourage[1].name !== ''">+2: {{ item.entourage[1].name + ', ' + item.entourage[1].gender + ', ' + item.entourage[1].location }}</p>
                        <p v-if="item.entourage[2].name !== ''">+3: {{ item.entourage[2].name + ', ' + item.entourage[2].gender + ', ' + item.entourage[2].location }}</p>
                    </td>
                </template>
            </v-data-table>
        </div>

        <!-- All events list -->
        <div v-else>
            <h2>Alle Events</h2>
            <v-data-table
                :headers="headers"
                :items="items"
                :header-props="headerProps"
                :footer-props="footerProps"
                no-data-text="Keine Events verfügbar"
                dense
                dark
                @click:row="setParticipants"
            />
        </div>

        <!-- Action Buttons -->
        <div class="d-flex justify-space-around align-center mt-8">
            <v-btn
                class="yellowBtn"
                @click="createQRCodes"
                :loading="generatorLoading"
                dark
            >
                Neue QR-Codes generieren
            </v-btn>
        </div>

        <!-- QRcode stuff -->
        <qrcode-vue
            v-for="(qrCodeString, index) in qrCodeGeneratorData"
            v-show="false"
            :key="`${qrCodeString}${index}`"
            :ref="`hiddenQrCodeContainer${index}`"
            :value="qrCodeString"
            size="200"
        />

        <QRScanDialog
            v-if="scanMode"
            :close-function="() => { scanMode = false; } "
            :user-group="'admin'"
            @QRCodeScanned="verifyQrCode"
        />

        <!--#region dialogs -->
        <v-dialog v-model="confirmBlockUsersDialog">
            <v-card
                width="400"
                style="background-color: black; max-width: 95vw"
            >
                <v-card-title style="color: white">
                    Nutzer sperren
                </v-card-title>
                <v-card-text style="color: white">
                    Wollen Sie alle Nutzer die sich für das aktuelle Event angemeldet haben, aber deren QR-Codes noch nicht gescannt wurden, blockieren?
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        class="yellowBtn"
                        @click="confirmBlockUsersDialog = false"
                        dark
                    >
                        Abbrechen
                    </v-btn>
                    <v-spacer />
                    <v-btn
                        class="yellowBtn"
                        @click="blockUsers"
                        dark
                    >
                        Blockieren
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Create new event form -->
        <v-dialog v-model="createEventDialog">
            <v-card
                width="400"
                style="background-color: black; max-width: 95vw"
            >
                <v-card-title style="color: white">
                    Neues Event anlegen
                </v-card-title>
                <v-card-text>
                    <v-text-field
                        v-model="name"
                        label="Eventname"
                        color="yellow"
                        class="mb-2"
                        dark
                        outlined
                        hide-details
                    />
                    <v-textarea
                        v-model="description"
                        label="Beschreibung"
                        color="yellow"
                        class="mb-2"
                        rows="1"
                        row-height="10"
                        auto-grow
                        no-resize
                        dark
                        outlined
                        hide-details
                    />
                    <v-text-field
                        v-model="date"
                        label="Datum"
                        color="yellow"
                        class="mb-2"
                        dark
                        outlined
                        hide-details
                    />
                    <v-textarea
                        v-model="location"
                        label="Ort"
                        color="yellow"
                        rows="1"
                        row-height="10"
                        auto-grow
                        no-resize
                        dark
                        outlined
                        hide-details
                    />
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        class="yellowBtn"
                        @click="createEvent"
                        dark
                    >
                        Anlegen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--#endregion -->
    </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import QrcodeVue from 'qrcode.vue';
import { PDFDocument, PageSizes, rgb } from 'pdf-lib';
import {compress, decompress} from 'shrink-string';
import QRScanDialog from "@/components/QRScanDialog";

export default {
    name: "EventOverview",
    components: {QrcodeVue, QRScanDialog},
    data: () => ({
        // translate v-data-table
        headerProps: { sortByText: 'Sortieren nach' },  // used if table goes into mobile
        footerProps: { itemsPerPageText: 'Zeilen', pageText: '{0}-{1} von {2}' },

        currentEvent: null,     // just the most recently created one
        headers: [
            { text: 'NR.', value: 'index' },
            { text: 'NAME', value: 'name' },
            { text: 'BESCHR.', value: 'description' },
            { text: 'TEILNEHMER.', value: 'computedParticipants' },
        ],
        items: [],
        qrCodeGeneratorData: ['no'],
        generatorLoading: false,

        scanMode: false,

        // create event
        createEventDialog: false,
        name: '',
        description: '',
        date: '',
        location: '',

        confirmBlockUsersDialog: false,

        showParticipants: false,
        participantsEvent: '',      // the event for which you are seeing the participants
        expanded: [],
        participantsItems: [],
        participantsHeaders: [
            { text: 'NR.', value: 'index' },
            { text: 'NAME', value: 'owner.name' },
            { text: 'GESCHLECHT', value: 'owner.gender' },
            { text: 'STUDIENGANG', value: 'owner.location' },
            /*{ text: 'ANWESEND', value: 'attending' },*/   // TODO: implement attending field (with yes/no)
            { text: '', value: 'data-table-expand' },
        ],
    }),
    async mounted() {
        await this.requestEvents();
    },
    methods: {
        ...mapActions('qrcodes', [ 'pullAllQrCodes', 'generateNewQrCodes' ]),
        ...mapActions('event', ['postEvent', 'getEvents', 'requestBlockUsers', 'setCodeAttending']),
        ...mapMutations('snackbar', ['showSnackbar']),

        async createQRCodes() {
            this.generatorLoading = true;
            const newCodes = await this.generateNewQrCodes({
                count: 10
            });
            this.qrCodeGeneratorData = [];
            await newCodes.reduce(async (previousPromise, codeObject) => {
                const previous = await previousPromise;
                const compressedKey = await compress(codeObject._id);
                const qrCodeKey = `${window.location.protocol}//www.${window.location.host}?key=${compressedKey}`;
                this.qrCodeGeneratorData.push(qrCodeKey);
                previous.push(qrCodeKey);
                return previous;
            }, Promise.resolve([]));

            const pdfDoc = await PDFDocument.create();
            const pdfPage = pdfDoc.addPage(PageSizes.A4);
            const pageWidth = pdfPage.getWidth();
            const pageHeight = pdfPage.getHeight();
            for (let i = 0; i < newCodes.length; i++) {
                const qrCode = this.$refs[`hiddenQrCodeContainer${i}`][0]
                    .$refs['qrcode-vue']
                    .toDataURL("image/png");
                const pngImage = await pdfDoc.embedPng(qrCode);

                pdfPage.drawRectangle({
                    x: i % 2 ? pageWidth * 0.55 : pageWidth * 0.05,
                    y: pageHeight * 0.825 - (i % 2 ? (i-1) / 2 : i/2) * (pageHeight * 0.2) - pageHeight * 0.02,
                    width: pageWidth * 0.4,
                    height: pageHeight * 0.18,
                    borderWidth: 2,
                    borderColor: rgb(0, 0, 0),
                    color: rgb(1,1,1),
                });

                const imageSize = pageHeight * 0.14;

                pdfPage.drawImage(pngImage, {
                    x: (i % 2 ? pageWidth * 0.55 : pageWidth * 0.05) + (pageWidth * 0.2 - imageSize/2),
                    y: pageHeight * 0.825  - (i % 2 ? (i-1) / 2 : i/2) * (pageHeight * 0.2),
                    width: imageSize,
                    height: imageSize,
                });
            }

            // Serialize the PDFDocument to bytes (a Uint8Array)
            const pdfBytes = await pdfDoc.save();

            await this.downloadFile(pdfBytes);
            this.generatorLoading = false;
        },

        // final download
        async downloadFile(uintArray) {
            let blob = await new Blob([uintArray]);
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = `Kevent-QRCodes.pdf`;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            setTimeout(function(){
                a.remove();
                window.URL.revokeObjectURL(url);
            }, 1000);
        },

        async createEvent() {
            let body = {
                name: this.name,
                description: this.description,
                location: this.location,
                date: this.date,
            }

            let res = await this.postEvent(body);
            let event = await res.json();

            if (res.status === 201) {
                this.createEventDialog = false
                this.name = '';
                this.location = '';
                this.date = '';
                this.description = '';
                this.showSnackbar({ message: 'Event erfolgreich angelegt.', color: 'success' });
                // TODO: reload/update page/events
            } else {
                this.showSnackbar({ message: 'Bei der Event-Erstellung ist ein Fehler aufgetreten.', color: 'error' });
            }
        },

        async requestEvents() {
            let res = await this.getEvents();
            let events = await res.json();
            if (events) {
                events = events.map((items, index) => ({...items, index: index+1}));    // add index to items
                this.currentEvent = events[events.length-1];   // latest created event is being used as current event
                this.items = events;
            }
        },

        setParticipants(item, properties) {
            this.participantsEvent = item;
            // TODO: compute attending field
            this.participantsItems = item.qrCodesBooked;
            // add index
            this.participantsItems = this.participantsItems.map((items, index) => ({...items, index: index+1}));
            this.showParticipants = true;
        },

        resetParticipants() {
            this.participantsEvent = '';
            this.participantsItems = [];
            this.showParticipants = false;
        },

        async blockUsers() {
            const res = await this.requestBlockUsers(this.currentEvent._id);

            if (res.status === 200) {
                this.confirmBlockUsersDialog = false;
                this.showSnackbar({ message: 'Nutzer wurden blockiert.', color: 'success' });
            } else {
                this.showSnackbar({ message: 'Beim Blockieren ist ein Fehler aufgetreten.', color: 'error' });
            }
        },

        async verifyQrCode(key) {
            const qrCodeId = await this.checkKeyFormat(key);

            if (qrCodeId !== false) {
                let body = {
                    codeId: qrCodeId,
                    eventId: this.currentEvent._id,
                };

                let res = await this.setCodeAttending(body);

                if (res.status === 200) {
                    this.showSnackbar({ color: 'success', message: 'QR-Code erfolgreich gescannt.' });
                    return;
                }
                // I guess if you leave the event but want to get back in that's ok -> using 'success' as color
                else if (res.status === 290) {
                    this.showSnackbar({ color: 'success', message: 'QR-Code wurde für dieses Event schon gescannt.' });
                    return;
                }
                else if (res.status === 404) {
                    this.showSnackbar({ color: 'error', message: 'QR-Code nicht gefunden.' });
                    return;
                }
                else if (res.status === 409) {
                    this.showSnackbar({ color: 'error', message: 'QR-Code ist gesperrt!' });
                    return;
                }
                // if eventId is invalid it can also not be cast to ObjectId, so this will probably not even happen
                else if (res.status === 480) {
                    this.showSnackbar({ color: 'error', message: 'Event nicht gefunden.' });
                    return;
                }
                else if (res.status === 490) {
                    this.showSnackbar({ color: 'error', message: 'QR-Code hat sich für dieses Event nicht eingebucht.' });
                    return;
                }
                else {
                    console.log('error');
                    this.showSnackbar({ color: 'error', message: 'Es ist ein Fehler aufgetreten. Versuchen Sie es erneut.' });
                    return;
                }
            }

            this.showSnackbar({ color: 'error', message: 'Beim Scannen ist ein Fehler aufgetreten. Versuchen Sie es erneut.' });
        },

        async checkKeyFormat(givenKey) {
            try {
                const decompressedKey = await decompress(givenKey);
                // Check if decompressedKey is of format uuid
                // TODO: Improve UUID Check, seems too easy
                // Regex from stackoverflow: https://stackoverflow.com/a/20988824
                let matches = !!decompressedKey.match(/^(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)/i);
                if (matches) {
                    return decompressedKey;
                } else {
                    return false;
                }
            } catch (e) {
                console.warn(e);
                return false;
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.btnContainer {
    flex-direction: row;
    gap: 8px;
}

.yellowBtn {
    color: var(--v-backgroundYellow-base) !important;
    border-radius: 0;
}

.multilineText {
    white-space: pre-wrap;
}

@media (max-width: 650px) {
    .btnContainer {
        flex-direction: column;
    }
}
</style>
