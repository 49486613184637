import * as backend from "../../api/backend";
import { checkResponseStatus } from "@/util/check";

const state = {

};

const mutations = {

};

const actions = {
    async postEvent({commit, dispatch}, body) {
        try {
            const res = await backend.postEvent(body);
            await checkResponseStatus(201, res);
            return res;
        }
        catch (e) {
            console.error(e);
            return false;
        }
    },

    async getEvents({commit, dispatch}) {
      try {
          const res = await backend.getEvents();
          await checkResponseStatus(200, res);
          return res;
      }
      catch (e) {
          console.error(e);
          return false;
      }
    },

    async getCurrentEvent({commit, dispatch}, key) {
        try {
            const res = await backend.getCurrentEvent(key);
            await checkResponseStatus(200, res);
            return res;
        }
        catch (e) {
            console.error(e);
        }
    },

    async registerForEvent({ commit, dispatch }, params) {
        try {
            const eventId = params.eventId;
            delete params.eventId;
            const res = await backend.registerForEvent(eventId, params);
            await checkResponseStatus(200, res);
            return res;
        }
        catch (e) {
            console.error(e);
        }
    },

    async requestBlockUsers({ commit, dispatch}, params) {
        try {
            const res = await backend.requestBlockUsers(params);
            await checkResponseStatus(200, res);
            return res;
        } catch (e) {
            console.error(e);
        }
    },

    async setCodeAttending({commit, dispatch}, body) {
        try {
            return await backend.setCodeAttending(body);
        } catch (e) {
            console.error(e);
        }
    },
};

const getters = {

};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
