import * as backend from '../../api/backend';
import { checkResponseStatus } from '../../util/check';
import { v4 as uuidv4 } from 'uuid';

const defaultState = {
    stayLoggedIn: false,
    reloadLoggedIn: false,
    token: "",
    account: {},

    // Used for identifying users device,
    // should be generated once per device
    deviceToken: null,
};

const mutations = {
    login(state, token) {
        state.token = token;
    },
    setAccount(state, account) {
        state.account = account;
    },
    setStayLoggedIn(state, stay) {
        state.stayLoggedIn = stay;
    },
    setReloadLoggedIn(state, reload) {
        state.reloadLoggedIn = reload;
    },
    setDeviceToken(state, deviceToken) {
        state.deviceToken = deviceToken;
    },
    logout(state) {
        state.token = "";
        state.account = null;
        state.stayLoggedIn = false;
    },
};

const actions = {
    async loginUser({ commit, dispatch, state }, { accountName, password }) {
        const regexNoWhitespacesAtStartEnd = /^\s+|\s+$/gm;
        password = password.replace(regexNoWhitespacesAtStartEnd, '');
    },

    autoLogoutUser({ commit, state }) {
        if (!state.stayLoggedIn && !state.reloadLoggedIn) {
            commit('logout');
        }
        commit('setReloadLoggedIn', false);
    },
    async logoutUser({ commit }) {
        commit('logout');
    },
    async reloadDontLogout({ commit }, reload) {
        commit('setReloadLoggedIn', reload);
    },

    // User QRCode verification
    async loginUserWithKey({ commit }, compressedKey) {
        try {
            const response = await backend.verifyQRCodeKey(compressedKey);
            await checkResponseStatus(200, response);
            commit('setAccount', response.json());
            return true;

        } catch (e) {
            console.error(e);
            return false;
        }
    }
};

const getters = {
    account: state => state.account,
    accountId: state => state.account ? state.account._id : undefined,
    accountRole: state => state.account ? state.account.role : undefined,
}

export default {
    namespaced: true,
    state: defaultState,
    mutations,
    actions,
    getters,
};
