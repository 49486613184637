<template>
    <div
        id="background"
        class="blackBackgroundWhiteText d-flex flex-column justify-center align-center"
    >
        <div
            id="container"
            class="d-flex flex-column justify-center align-center"
        >
            <v-icon
                size="100"
                color="white"
            >
                mdi-star
            </v-icon>

            <div style="width: 100%">
                <div
                    class="d-flex flex-column align-center my-4"
                    style="gap: 16px; width: 100%"
                >
                    <div class="textDiv text-center">{{ currentEvent.name }}</div>
                    <div class="textDiv text-center">{{ currentEvent.date }}</div>
                    <div class="textDiv text-center">{{ currentEvent.location }}</div>
                    <div class="textDiv text-center">{{ currentEvent.description }}</div>
                </div>

                <div v-if="!acceptedEvent">
                    <div
                        id="btnContainer"
                        class="d-flex justify-space-between mt-4"
                    >
                        <v-btn
                            class="invertedYellowBtn"
                            dark
                            @click="goHome"
                        >
                            Ablehnen
                        </v-btn>

                        <v-btn
                            class="yellowBtn"
                            dark
                            @click="accept"
                        >
                            Teilnehmen*
                        </v-btn>
                    </div>

                    <p class="mt-8"><small>* Wenn du auf teilnehmen drückst, aber nicht zum Event erscheinst, wird dein QR-Code für alle kommenden Events gesperrt.</small></p>
                </div>

                <div
                    v-else
                    class="d-flex flex-column align-center"
                >
                    <p>Sie nehmen am Event teil</p>
                    <v-btn
                        class="yellowBtn mt-2"
                        dark
                        @click="goHome"
                    >
                        Zur Startseite
                    </v-btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
    name: "Event",
    mounted() {
        this.getEventInfo();
    },
    data() {
        return {
            currentEvent: [],
            acceptedEvent: false,     // set true if accepting event was successful/key is in qrCodesBooked
        }
    },
    computed: {
        ...mapGetters('qrcodes', ['key']),
    },
    methods: {
        ...mapMutations('snackbar', [ 'showSnackbar' ]),
        ...mapActions('event', ['getCurrentEvent', 'registerForEvent']),

        async getEventInfo() {
            let myKey = await this.key;
            let res = await this.getCurrentEvent(myKey);
            this.currentEvent = await res.json();
            if (this.currentEvent.bookedByYou) {
                this.acceptedEvent = true;
            }
        },

        goHome() {
            this.$router.push('start');
        },

        async accept() {
            let compressedKey = await this.key;
            let eventId = this.currentEvent._id;

            let body = {
                eventId: eventId,
                compressedKey: compressedKey,
            };

            let res = await this.registerForEvent(body);

            if (res.status === 200) {
                this.showSnackbar({ message: 'Sie nehmen am Event teil.', color: 'success' });
                this.acceptedEvent = true;
            } else {
                this.showSnackbar({ message: 'Fehler bei der Übermittlung. Bitte versuchen Sie es später nochmal.', color: 'error' });
            }
        },
    }
}
</script>

<style lang="scss" scoped>
#background {
    padding: 5vh 0;
    min-height: 100vh;
    width: 100vw;
}

#container {
    width: 60vw;
    max-width: 60vw;
}

.textDiv {
    white-space: pre-wrap;
    padding: 4px 8px;
    background-color: white;
    color: black;
    width: 60%;
}

.yellowBtn {
    background-color: var(--v-backgroundYellow-base) !important;
    color: black !important;
    border-radius: 0;
}

.invertedYellowBtn {
    background-color: #222222 !important;
    color: var(--v-backgroundYellow-base) !important;
    border-radius: 0;
}

.multilineText {
    white-space: pre-wrap;
}

@media (max-width: 800px) {
    #container {
        width: 90vw;
        max-width: 95vw;
    }

    #btnContainer {
        flex-direction: column;
        gap: 8px;
    }
}
</style>
