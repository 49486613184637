<template>
    <v-dialog
        v-model="dialogOpen"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
        <v-card>
            <div style="max-height: 100vh">
                <div id="qrCodeUI">
                    <v-btn
                        id="qrCodeClose"
                        x-small
                        class="mr-0"
                        @click="closeFunction"
                    >
                        <img
                            :src="closeIcon"
                            style="height: 40px; filter: brightness(1000%)"
                            alt="QR-Code Login schließen"
                        >
                    </v-btn>
                    <div class="qrCodeVisorContainer">
                        <div class="qrCodeVisor" />
                    </div>
                </div>
                <qrcode-stream
                    ref="qrCodeScanner"
                    class="qrCodeScanner"
                    style="max-height: 100vh !important; overflow: hidden"
                    @decode="onDecode"
                    @init="onInit"
                >
                    <div class="qrCodeVisorContainer">
                        <div class="qrCodeVisor">
                            <div>
                                <img :src="qrIcon">
                            </div>
                        </div>
                    </div>
                </qrcode-stream>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import { QrcodeStream } from "vue-qrcode-reader";
import closeIcon from "../assets/abbrechen-08.svg";
import qrIcon from "../assets/sample_qrvisor.png";

export default {
    name: "QRScanDialog",
    components: {
        QrcodeStream,
    },
    props: {
        closeFunction: { type: Function, required: true },
        // 'user' or 'admin'
        userGroup: { type: String, required: true }
    },
    data: () => ({
        closeIcon,
        qrIcon,

        dialogOpen: true,
    }),
    methods: {
        ...mapMutations('snackbar', ['showSnackbar']),
        ...mapActions('qrcodes', ['setMyKey', 'setScannedKey']),

        async onInit(promise) {
            try {
                await promise;
            } catch (e) {
                console.error(e);
                if (e.name === 'NotReadableError') {
                    this.showSnackbar({ message: "QR-Code nicht gültig!"});
                } else  if (e.name === 'NotAllowedError'){
                    this.showSnackbar({ message: `Fehler! ${e.name}`});
                } else if (e.name === 'NotFoundError' || e.name === 'OverconstrainedError') {
                    this.showSnackbar({ message: "Das Gerät scheint keine nutzbare Kamera zu besitzen. Bitte schließe eine an oder wechsel das Gerät."});
                } else {
                    this.showSnackbar({ message: `Fehler! ${e.name}`});
                }
            }
        },
        async onDecode(result) {
            // TODO: Improve Wrong QRCode and Error handling
            if (result.length > 0) {
                const check = result.includes(window.location.host) && result.includes('key');
                if (check) {
                    const URLParams = result.split('?');
                    const compressedKey = URLParams[1].substring(4);
                    if (this.userGroup === 'user') {
                        await this.setMyKey(compressedKey);
                    }
                    this.$emit('QRCodeScanned', compressedKey);
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
#qrCodeUI {
    z-index: 10;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}

#qrCodeClose {
    position: absolute;
    background-color: var(--v-dunkelgrau-base);
    width: 49px !important;
    height: 49px !important;
    top: 10px;
    right: 10px;
    z-index: 11;
}

.qrCodeVisorContainer {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.qrCodeVisor {
    position: absolute;
    width: 30% !important;
    height: 0 !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    filter: opacity(50%);

    div {
        width: 100%;
        padding-top: 100%; /* 1:1 Aspect Ratio */
        position: relative; /* If you want text inside of it */
        transform: translate(0%, -25%);
        img {
            display: block;
            width: 100%;
            height: 100%;
            transform: translate(0%, -100%);
        }
    }
}

#qrCodeVisorContainer {
    position: relative;
    width: 100%;
    height: 100%;
}

.v-snack.v-snack--top {
    bottom: initial; /* or auto */
}

.v-snack.v-snack--bottom {
    top: initial; /* or auto */
}
</style>
