<template>
    <v-snackbar
        v-model="snackbarVisible"
        :timeout="snackbarTimeout"
        :color="snackbarColor"
    >
        <template
            v-if="snackbarClosable"
            v-slot:action="{ attrs }"
        >
            <v-btn
                text
                v-bind="attrs"
                small
                @click="clickCloseSnackbar"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
        {{ snackbarMessage }}
    </v-snackbar>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "Snackbar",
  components: {},
  data: () => ({
    snackbarVisible: false,
  }),
  computed: {
    ...mapState("snackbar", [
      "snackbarMessage",
      "snackbarTimeout",
      "snackbarMessageNumber",
      "snackbarColor",
      "snackbarClosable",
    ]),
  },

  watch: {
    snackbarMessageNumber() {
      // Set first to false then true to reset timeout
      this.snackbarVisible = true;
    },
  },
  methods: {
    clickCloseSnackbar() {
      this.snackbarVisible = false;
    },
  },
};
</script>
<style scoped>
</style>
