<template>
    <div
        id="background"
        class="blackBackgroundWhiteText d-flex flex-column justify-center align-center"
    >
        <div
            id="container"
            class="d-flex flex-column justify-center align-center"
        >
            <v-icon
                size="100"
                color="white"
            >
                mdi-account
            </v-icon>

            <div class="mt-4" style="max-width: 90vw">
                <!--#region main person which is required to go to next page -->
                <div class="formRow d-flex">
                    <v-text-field
                        v-model="name1"
                        label="Name*"
                        solo
                        hide-details
                    />

                    <v-select
                        v-model="gender1"
                        :items="genders"
                        :menu-props="{ bottom: true, offsetY: true }"
                        label="Geschlecht*"
                        solo
                        hide-details
                    />

                    <v-select
                        v-model="course1"
                        :items="courses"
                        :menu-props="{ bottom: true, offsetY: true }"
                        label="Studiengang*"
                        solo
                        hide-details
                    />
                </div>

                <p class="text-center mt-2">Nach der Eingabe erhälst Du weitere Eventdetails.</p>
                <!--#endregion -->

                <!--#region additional people -->
                <div
                    class="flexColMobile mt-6 mb-2"
                >
                    <h3>Weitere Personen</h3>
                    <div class="d-flex justify-center">
                        <v-btn
                            class="yellowBtn"
                            dark
                            @click="addAdditionalPeople = !addAdditionalPeople"
                        >
                            {{ addAdditionalPeople ? 'Schließen' : 'Hinzufügen' }}
                        </v-btn>
                    </div>
                </div>

                <div v-if="addAdditionalPeople">
                    <div class="formRow d-flex mb-2">
                        <p class="hideOnMobile d-flex align-center">1.</p>

                        <v-text-field
                            v-model="name2"
                            label="Name"
                            solo
                            hide-details
                        />

                        <v-select
                            v-model="gender2"
                            :items="genders"
                            :menu-props="{ bottom: true, offsetY: true }"
                            label="Geschlecht"
                            solo
                            hide-details
                        />

                        <v-select
                            v-model="course2"
                            :items="courses"
                            :menu-props="{ bottom: true, offsetY: true }"
                            label="Studiengang"
                            solo
                            hide-details
                        />
                    </div>

                    <hr class="showOnMobile mb-2">

                    <div class="formRow d-flex mb-2">
                        <p class="hideOnMobile d-flex align-center">2.</p>

                        <v-text-field
                            v-model="name3"
                            label="Name"
                            solo
                            hide-details
                        />

                        <v-select
                            v-model="gender3"
                            :items="genders"
                            :menu-props="{ bottom: true, offsetY: true }"
                            label="Geschlecht"
                            solo
                            hide-details
                        />

                        <v-select
                            v-model="course3"
                            :items="courses"
                            :menu-props="{ bottom: true, offsetY: true }"
                            label="Studiengang"
                            solo
                            hide-details
                        />
                    </div>

                    <hr class="showOnMobile mb-2">

                    <div class="formRow d-flex">
                        <p class="hideOnMobile d-flex align-center">3.</p>

                        <v-text-field
                            v-model="name4"
                            label="Name"
                            solo
                            hide-details
                        />

                        <v-select
                            v-model="gender4"
                            :items="genders"
                            :menu-props="{ bottom: true, offsetY: true }"
                            label="Geschlecht"
                            solo
                            hide-details
                        />

                        <v-select
                            v-model="course4"
                            :items="courses"
                            :menu-props="{ bottom: true, offsetY: true }"
                            label="Studiengang"
                            solo
                            hide-details
                        />
                    </div>
                </div>
                <!--#endregion -->

                <div class="d-flex justify-center mt-8">
                    <v-btn
                        class="yellowBtn"
                        :disabled="mainPersonInvalid"
                        dark
                        @click="register"
                    >
                        Enter
                    </v-btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";

export default {
    name: "Register",
    computed: {
        ...mapGetters('qrcodes', ['key']),
        ...mapGetters('auth', ['account']),

        mainPersonInvalid() {
            let invalid = false;

            if (this.name1.replace(/\s/g, "") === "") {
                invalid = true;
            }
            if (this.gender1 === null) {
                invalid = true;
            }
            if (this.course1 === null) {
                invalid = true;
            }

            return invalid;
        }
    },
    data() {
        return {
            genders: ['Männlich', 'Weiblich'],
            courses: ['Software Engineering', 'Maschinenbau', 'BWL', 'Medien'],

            // owner is required
            name1: '',
            gender1: null,
            course1: null,

            addAdditionalPeople: false,
            name2: '',
            gender2: null,
            course2: null,
            name3: '',
            gender3: null,
            course3: null,
            name4: '',
            gender4: null,
            course4: null,
        }
    },
    async mounted() {
        await this.ifAlreadyRegistered();
        this.courses.sort();
    },
    methods: {
        ...mapActions('person', ['createPerson']),
        ...mapActions('qrcodes', ['registerQrCode']),
        ...mapMutations('snackbar', ['showSnackbar']),

        async ifAlreadyRegistered() {
            let acc = await this.account;

            if (acc.owner) {
                if (acc.owner.name.replace(/\s/g, "") !== "") {
                    // qrCode already has an owner registered
                    this.$router.push('event');
                }
            }
        },

        async register() {
            let owner = {
                name: this.name1,
                gender: this.gender1,
                location: this.course1,
            }

            // TODO: only send people that are not blank/null
            let entourage = [
                {
                    name: this.name2,
                    gender: this.gender2,
                    location: this.course2,
                },
                {
                    name: this.name3,
                    gender: this.gender3,
                    location: this.course3,
                },
                {
                    name: this.name4,
                    gender: this.gender4,
                    location: this.course4,
                }
            ];

            let compressedKey = await this.key;

            let body = {
                key: compressedKey,
                owner: owner,
                entourage: entourage,
            }

            let res = await this.registerQrCode(body);
            if (res.status === 200) {
                this.$router.push('event');
            }
            else {
                this.showSnackbar({ message: 'Bei der Registrierung ist ein Fehler aufgetreten.', color: 'error' });
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.v-input {
    border-radius: 0 !important;
    text-transform: uppercase;
}

#background {
    padding: 5vh 0;
    min-height: 100vh;
    width: 100vw;
    max-width: 100vw;
}

#container {
    max-width: 60vw;
}

.input-field {
    background-color: white !important;
}

.yellowBtn {
    background-color: var(--v-backgroundYellow-base) !important;
    color: black !important;
    border-radius: 0;
}

.formRow {
    gap: 16px;
}

.showOnMobile {
    display: none;
}

.hideOnMobile {
    display: initial;
}

.flexColMobile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media (max-width: 800px) {
    #container {
        max-width: 90vw;
    }

    .formRow {
        display: flex;
        flex-direction: column;
    }

    .showOnMobile {
        display: block;
    }

    .hideOnMobile {
        display: none;
    }

    .flexColMobile {
        display: flex;
        flex-direction: column;
        justify-content: center;

        h3 {
            text-align: center;
        }
    }
}
</style>
