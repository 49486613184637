import Vue from 'vue';
import VueRouter from 'vue-router';
import vuetify from './plugins/vuetify';
import store from './store';

import App from './App.vue';
import Version from './components/Version';
import Login from './components/Login';
import Home from './views/Home';
import Admin from "@/views/Admin";
import Register from "@/views/Register";
import Event from "@/views/Event";

Vue.config.productionTip = false;
Vue.use(VueRouter);

Vue.config.devtools = true

export const bus = new Vue();

// Direct only authenticated users with a given role to the destination route
// others will be redirected to login
const auth = (roles) => async (to, from, next) => {
    const account = store.state.auth.account;

    // If user is not logged in on load
    if (!account) {
        next({ name: 'login' });
        return;
    }

    const revalidatedAccount = await store.dispatch('auth/revalidateUserLogin');
    if (!(revalidatedAccount && roles.find(role => role === revalidatedAccount.role))) {
        next({ name: 'login' });
        return;
    }
    next();
}

const routes = [
    { path: '', redirect: '/home' },
    { path: '*', redirect: '/home' },
    { path: '*\\.map' }, // Exclude Javascript maps from vue router https://github.com/vuejs/vue-router/issues/927#issuecomment-350221829
    { path: '*\\.js' }, // Exclude js from vue router https://github.com/vuejs/vue-router/issues/927#issuecomment-350221829
    { path: '*\\.css' }, // Exclude css from vue router https://github.com/vuejs/vue-router/issues/927#issuecomment-350221829
    {
        path: '/version',
        name: 'version',
        component: Version,
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
    },
    //#region User pages
    {
        path: '/home',
        name: 'home',
        component: Home,
    },
    {
        path: '/register',
        name: 'register',
        component: Register,
    },
    {
        path: '/event',
        name: 'event',
        component: Event,
    },
    //#endregion
    //#region Admin pages
    {
        path: '/admin',
        name: 'admin',
        component: Admin,
    }
    //#endregion
];

const router = new VueRouter({
    mode: 'history',
    routes,
});

function hasQueryParams(route) {
    return !!Object.keys(route.query).length
}

router.beforeEach((to, from, next) => {
    if (!hasQueryParams(to) && hasQueryParams(from)) {
        next({ name: to.name, query: from.query });
    } else {
        next()
    }
});

const main = new Vue({
    store,
    router,
    vuetify,
    icons: {
        iconfont: 'fa',
    },
    render: h => h(App)
}).$mount('#app');

