<template>
    <div>
        <div
            v-if="!loginValid"
            class="d-flex flex-column justify-center align-center backgroundAdmin"
        >
            <v-icon
                size="100"
                class="colorBlack"
            >
                mdi-account
            </v-icon>
            <h1 class="colorBlack">
                ADMIN
            </h1>
            <input
                v-model="password"
                type="password"
                class="pwInput"
                placeholder="PASSWORT EINGEBEN"
                @keydown.enter="login"
            >
            <v-btn
                class="enterBtn"
                @click="login"
            >
                Enter
            </v-btn>
        </div>
        <div
            v-else
            class="blackBackgroundWhiteText pa-4"
            style="min-height: 100vh; max-width: 100vw;"
        >
            <EventOverview />

            <div class="d-flex justify-center mt-4">
                <v-btn
                    class="yellowBtn"
                    dark
                    @click="logout"
                >
                    Abmelden
                </v-btn>
            </div>
        </div>

    </div>
</template>

<script>
import EventOverview from "../components/EventOverview";
import * as backend from "../api/backend";
import {mapMutations, mapState} from "vuex";

export default {
    name: "Admin",
    components: {EventOverview},
    data() {
        return {
            loginValid: false,
            password: '',
        }
    },
    computed: {
        ...mapState("admin",["adminToken"]),
    },
    async mounted() {
        const res = await backend.validateAdminToken(this.adminToken);
        if (res.status === 200) {
            this.loginValid = true;
        }
    },
    methods: {
        ...mapMutations('admin', ['setAdminToken']),

        async login() {
            let pw = this.password;
            let res = await backend.validateAdminPassword(pw);
            if (res.status === 403) {
                window.alert('Ungültiges Passwort');
                this.setAdminToken('');
                return;
            }
            res = await res.json();
            this.setAdminToken(res.value);
            this.loginValid = true;
            this.password = '';
        },

        logout() {
            this.password = '';
            this.setAdminToken('')
            this.loginValid = false;
        }
    },
}
</script>

<style lang="scss">
.backgroundAdmin {
    height: 100vh;
    width: 100vw;
    background-color: var(--v-backgroundYellow-base);
    color: white;
}

.colorBlack {
    color: var(--v-black-base) !important;
}

.pwInput {
    background-color: var(--v-weiss-base);
    color: var(--v-black-base) !important;
    height: 5vh;
    text-align: center;
}

.enterBtn {
    background-color: var(--v-black-base) !important;
    color: var(--v-backgroundYellow-base) !important;
    margin-top: 5vh;
    border-radius: 0;
    width: 10vw
}

.yellowBtn {
    color: var(--v-backgroundYellow-base) !important;
    border-radius: 0;
}
</style>
