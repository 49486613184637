const defaultState = {
    adminToken: '',  // amount of elements in current selection
};

const getters = {
    getAdminToken: state => state.adminToken,
};

const mutations = {
    setAdminToken: (state, val) => {
        state.adminToken = val;
        // console.log('new Token:',state.adminToken);
    },
};

export default {
    namespaced: true,
    state: defaultState,
    getters,
    mutations,
};
