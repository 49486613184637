import * as backend from "../../api/backend";
import { checkResponseStatus } from "../../util/check";

const state = {
    qrCodes: [],
    ownKey: '',     // key of qrCode you scanned
};

const mutations = {
    setQrCodes(state, qrCodes) {
        state.qrCodes = qrCodes;
    },
    setOwnKey(state, key) {
        state.ownKey = key;
    }
};

const actions = {
    async pullAllQrCodes({ commit }) {
        try {
            const response = await backend.getQrCodes();
            await checkResponseStatus(200, response);
            commit('setQrCodes', response.json());
        } catch (e) {
            console.error(e);
        }
    },

    async generateNewQrCodes({ commit, dispatch }, payload) {
        try {
            const response = await backend.postQrCodes(payload);
            await checkResponseStatus(201, response);
            // dispatch('pullAllQrCodes');
            return await response.json();

        } catch (e) {
            console.error(e);
            return false;
        }
    },

    async setMyKey({ commit, dispatch}, key) {
      try {
          commit('setOwnKey', key);
      }
      catch (e) {
          commit('setOwnKey', '');
      }
    },

    async registerQrCode({ commit, dispatch }, params) {
        try {
            const key = params.key;
            delete params.key;
            const res = await backend.registerQRCode(key, params);
            await checkResponseStatus(200, res);
            return res;
        }
        catch (e) {
            console.error(e);
            return false;
        }
    }
};

const getters = {
    qrCodes: state => state.qrCodes,
    qrCode: state => id => state.qrCodes.find(item => item._id === id),
    key: state => state.ownKey,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
